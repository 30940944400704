<div class="container p-2">
  <div class="row pt-50">
    <div class="col text-center">
      <img class="image-icon" width="250" src="../../assets/img/DTIF-logo_full-colour.svg" alt="">
    </div>
  </div>
  <div class="row pt-2">
    <div class="col">
      <h1 class="title">Sign Up</h1>
      <p class="sub-title">Enter your email address and we’ll send you a private sign up link.</p>
    </div>
  </div>
  <div class="row pt-50" *ngIf="!onProcessing">
    <div class="col">
      <div [ngClass]="{'success':onSuccess ,'error': onError }" class="alert">{{errorMessage}}</div>
    </div>
  </div>
  <div class="row" [ngClass]="onProcessing ? 'pt-1-50':'pt-50'">
    <div class="col">
      <form [formGroup]="formGroup" class="" (ngSubmit)="register()">
        <div class="row">
          <div class="col first-name">
            <mat-form-field class="" color="primary" appearance="outline" floatLabel="auto">
              <mat-label class="field-label-size">First Name</mat-label>
              <input matInput type="text" formControlName="firstName" placeholder="First Name" class="field-size" maxlength="50">
              <mat-error *ngIf="firstNameHasErrorRequired">{{VALIDATIONS_MESSAGE.REQUIRED}}</mat-error>
              <mat-error *ngIf="!firstNameHasErrorRequired && inputFirstName.errors">Please enter valid first name</mat-error>
            </mat-form-field>
          </div>
          <div class="col last-name">
            <mat-form-field class="" color="primary" appearance="outline" floatLabel="auto">
              <mat-label class="field-label-size">Last Name</mat-label>
              <input matInput type="text" formControlName="lastName" placeholder="Last Name" class="field-size" maxlength="50">
              <mat-error *ngIf="lastNameHasErrorRequired">{{VALIDATIONS_MESSAGE.REQUIRED}}</mat-error>
              <mat-error *ngIf="!lastNameHasErrorRequired && inputLastName.errors">Please enter valid last name</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row pt-50">
          <div class="col">
            <mat-form-field class="" color="primary" appearance="outline" floatLabel="auto">
              <mat-label class="field-label-size">Email</mat-label>
              <input matInput type="email" formControlName="email" placeholder="Email" class="field-size" maxlength="255">
              <mat-error *ngIf="inputEmail.hasError('required')">{{VALIDATIONS_MESSAGE.REQUIRED}}</mat-error>
              <mat-error *ngIf="inputEmail.hasError('pattern')">Please enter valid email</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row pt-50">
          <div class="col">
            <mat-form-field class="" color="primary" appearance="outline" floatLabel="auto">
              <mat-label class="field-label-size">Password</mat-label>
              <input matInput #passwordInput="matInput" [type]="passwordType" formControlName="password" placeholder="Password" class="field-size">
              <mat-icon class="visibility" (click)="showPassword = !showPassword" matSuffix>{{visibility}}</mat-icon>
              <mat-error *ngIf="inputPassword.hasError('required')">{{VALIDATIONS_MESSAGE.REQUIRED}}</mat-error>
              <mat-error *ngIf="inputPassword.hasError('pattern')">Your password is not strong enough.</mat-error>
            </mat-form-field>
            <!-- -->
            <div class="password-requirements fadeInUp row " [ngClass]="{'pt-50': inputPassword.hasError('pattern') || inputPassword.hasError('required')}"
              *ngIf="passwordInput.focused || inputPassword.touched ">
              <div class="col">
                <span class="title">Your new password must have:</span>
                <div class="container-items row pt-50">
                  <div class="col item"><small>⬤</small>&nbsp;&nbsp;&nbsp;At least 8 characters </div>
                  <div class="col item pl-50"><small>⬤</small>&nbsp;&nbsp;&nbsp;At least 1 number</div>
                </div>
                <div class="container-items row">
                  <div class="col item"><small>⬤</small>&nbsp;&nbsp;&nbsp;At least 1 lowercase character</div>
                  <div class="col item pl-50"><small>⬤</small>&nbsp;&nbsp;&nbsp;At least 1 special character</div>
                </div>
                <div class="container-items row">
                  <div class="col item"><small>⬤</small>&nbsp;&nbsp;&nbsp;At least 1 uppercase character</div>
                  <div class="col item"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-1-50">
          <div class="col">
            <button mat-raised-button disableRipple="true" color="accent" class="btn-block btn-send-link">Send link</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col pt-1  text-center">
      <p class="has-account-label">Already have an account? <a href="/">Log in</a></p>
    </div>
  </div>
</div>