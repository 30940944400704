import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VALIDATIONS, VALIDATIONS_MESSAGE } from 'src/app/shared/util/validations';
import { FormType } from '../../shared/models/enums';
import { LINKS } from 'src/app/shared/constant/defaults';


@Component({
  selector: 'dti-initiator-tab',
  templateUrl: './initiator-tab.component.html',
  styleUrls: ['./initiator-tab.component.scss']
})
export class InitiatorTabComponent {


  @Input('controls') formGroup!: FormGroup;
  @Input() formType: string = FormType.DLT;

  linkTermsAndCondition = LINKS.REGISTRATION_TERMS_AND_CONDITION;
  hasNativeToken = false;
  hasAuxiliaryToken = false;

  constructor(private formBuilder: FormBuilder) {
  }

  VALIDATIONS_MESSAGE = VALIDATIONS_MESSAGE;

  get isCreator(): boolean {
    return this.formGroup.value.isCreator as boolean
  }

  get hasLei(): boolean {
    return this.formGroup.value.hasLei as boolean
  }

  get isDLT(): boolean {
    return this.formType == FormType.DLT;
  }

  get initiatorData() {
    return this.formGroup.value;
  }

  get tokenType(): string {
    return this.formType == FormType.AUX ? `Token` : this.formType;
  }


  // radio button LEI on change
  setFieldsValidation(isReset = true) {
    const lei = this.formGroup.get('lei'),
      companyName = this.formGroup.get('companyName'),
      identifierCode = this.formGroup.get('businessIdentifierCode');
    // reset values when switching options
    if (isReset) {
      lei?.reset();
      companyName?.reset();
      identifierCode?.reset();
    }
    // clear validators
    lei?.clearValidators();
    companyName?.clearValidators();
    identifierCode?.clearValidators();
    if (this.isCreator) {
      if (this.hasLei) {
        lei?.setValidators([Validators.required, Validators.pattern(VALIDATIONS.ALPHA_NUMERIC_MAX20)]);
      } else {
        companyName?.setValidators([Validators.required, Validators.maxLength(255), Validators.pattern(VALIDATIONS.COMPANY_NAME)]);

        identifierCode?.setValidators([Validators.pattern(VALIDATIONS.ALPHA_NUMERIC_MIN8_MAX11)]);
      }
    }
    lei?.updateValueAndValidity();
    companyName?.updateValueAndValidity();
    identifierCode?.updateValueAndValidity();
  }


}
