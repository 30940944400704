import { Component, HostBinding } from '@angular/core';
import { UserService } from 'src/app/data/user.service';
import { MyRequestsService } from 'src/app/services/my-requests.service';
import { LINKS } from 'src/app/shared/constant/defaults';

@Component({
  selector: 'dti-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  linkTermsAndCondition = LINKS.REGISTRATION_TERMS_AND_CONDITION;
  dataSource: any = [];
  showNotif = true;
  hasRequest = true;

  get name(): string {
    return this.user.name;
  }

  get nickname(): string {
    return this.user.name.split(' ')[0];
  }

  get email(): string {
    return this.user.email;
  }

  get picture(): string {
    return this.user.picture;
  }

  get hasRecentRequest(): boolean {
    return !!this.dataSource.length;
  }

  @HostBinding('class') get layout(): string { return this.showNotif ? 'three-rows' : 'four-rows'; }

  constructor(private user: UserService, private myRequestService: MyRequestsService) {
    this.user.$email.subscribe((email) => {
      if (email.length) {
        const startAt = 0, maxResult = 4;
        this.myRequestService.getRequests(email, startAt, maxResult).subscribe((data) => {
          this.dataSource = data;
          this.hasRequest = false;
        });
      }
    });
  }


}
