import { Component } from '@angular/core';
import { LINKS } from '../shared/constant/defaults';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {

  linkTermsAndCondition = LINKS.REGISTRATION_TERMS_AND_CONDITION;
  
}
