<div class="title">
    <label>DTI Allocation</label>
</div>
<div class="content">
    <label class="content-title">
        Select the relevant request
    </label>
    <p class="terms-and-condition">
        Please note that effective 8 July 2024, a fee of €180 will be charged for each allocated DTI (<a href="https://dtif.org/fee-model/" target="_blank">Fee Model</a>). Payment request will be sent to you once the request has been validated. The DTI will be allocated within 24 hours after the payment is received. Full terms and conditions are available <a [href]="linkTermsAndCondition" target="_blank">here</a>.
    </p>
    <a class="link-page" routerLink="/distributed-ledger-token">
        <img class="image-icon" src="../../assets/img/ledger.svg" alt="">
        <label class="link-details" for="">
            <span class="link-title">Distributed Ledger Technology Registration Request</span>
            <span class="link-title-description">
                Please use this form to submit a request to register a distributed ledger and its native token (if relevant).
                Kindly note that only ledgers with native or auxiliary tokens are within the scope of the DTI ISO 24165.
            </span>
        </label>
        <i class="forward-icon material-icons-font material-icons primary-color"> arrow_forward_ios </i>
    </a>
    <a class="link-page" routerLink="/auxiliary-token">
        <img class="image-icon" src="../../assets/img/token-icon.svg" alt="">
        <label class="link-details" for="">
            <span class="link-title">Auxiliary Digital Token Registration Request</span>
            <span class="link-title-description">
                Please use this form to submit a request to register an auxiliary digital token (a non-native digital token created as an application on an existing distributed ledger), such as ERC-20
                smart contract.
            </span>
        </label>
        <i class="forward-icon material-icons-font material-icons primary-color"> arrow_forward_ios </i>
    </a>
</div>